@import url('https://fonts.googleapis.com/css2?family=Carlito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Carlito', sans-serif;
}

body {
  background: #f3f3f3;
}

.payment_form {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 600px;
  max-width: calc(100% - 20px);
}

.card .card-input {
  padding: 10px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 10px 0;
}

.card .card-input::focus {
  border-bottom: 2px solid red;
}

.card label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  letter-spacing: .5px;
  margin-top: 10px;
  text-transform: capitalize;
}

.card button {
  font-size: 20px;
  padding: 5px;
  width: 150px;
  margin-top: 15px;
  border: none;
  outline: none;
  background: #ff5555;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.card button:hover {
  background: #d63031;
}

.card input {
  outline: none;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  font-size: 18px;
  padding: 10px 0;
  color: #000;
}

.card input::placeholder {
  color: #666;
  opacity: 1;
}

.coupon-container {
  margin-top: 10px;
}

.coupon-container section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupon-container section button {
  font-size: 16px;
  width: 100px;
  background: #0984e3;
}

.coupon-container section button:hover {
  background: #055b9d;
}

.coupon-container section input {
  width: calc(100% - 140px);
}

.card-container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  font-size: 24px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
  color: #666;
  margin-top: -15px;
}

.close:hover {
  color: #000;
}

.err {
  color: red;
  font-size: 14px;
  margin-top: 2px;
}

.pricing-table {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background: #ecf0f1;
}

.plan {
  width: 300px;
  max-width: 100%;
  background: #fff;
  padding: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.plan .plan-name {
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.plan .price-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.plan .price {
  text-align: center;
  font-size: 20px;
  padding: 5px 0;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.plan .price::after {
  content: '';
  background: #ff5555;
  height: 4px;
  border-radius: 100px;
  position: absolute;
  bottom: -5px;
  right: 15%;
  width: 50%;
}

.plan .list {
  display: flex;
  flex-direction: column;
}

.plan .list span {
  margin: 5px 0;
  color: #000;
  display: flex;
  align-items: center;
}

.plan .list span img {
  width: 15px;
  margin-right: 10px;
}

.plan button {
  font-size: 20px;
  padding: 5px;
  width: 150px;
  margin-top: 15px;
  border: none;
  outline: none;
  background: #ff5555;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.plan button:hover {
  background: #d63031;
}

.plan .list-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.status-container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
}

.status {
  width: 700px;
  height: 700px;
  max-width: 100%;
  max-height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.status .loader {
  width: 150px;
  height: 150px;
  border: 13px solid #ecf0f1;
  border-radius: 50%;
  border-top: 13px solid #bdc3c7;
  animation: rotate 1000ms linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.status p {
  font-size: 18px;
  text-align: center;
  letter-spacing: .5px;
  line-height: 25px;
  margin-top: 20px;
  max-width: 500px;
}

.success {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
}

.success .status-icon {
  width: 300px;
  height: 300px;
  margin-top: -70px;
}

.status .success p {
  font-size: 18px;
  text-align: center;
  margin-top: -40px;
  letter-spacing: .5px;
  line-height: 25px;
}

.got-it {
  margin-top: 20px;
  font-size: 20px;
  padding: 5px 30px;
  cursor: pointer;
  border: none;
  outline: none;
  background: #ff5555;
  color: #fff;
  border-radius: 6px;
}

.got-it:hover {
  background: #d63031;
}

.fail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
}

.fail .status-icon {
  width: 200px;
  height: 200px;
}

.status .fail p {
  font-size: 18px;
  text-align: center;
  letter-spacing: .5px;
  line-height: 25px;
  margin-top: 10px;
}